<template>
    <div>
        <b-card border-variant="danger" v-if="errore_caricamento">
          <b-row class="match-height">
            <b-col
              md="8"
            >
              <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni iniziali</h4>
              <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
            </b-col>
            <b-col 
              md="4"
              class="text-center"
            >
              <b-button
              variant="primary"
              :to="{ name: 'crm-ricerche-index' }"
              >
                  <feather-icon
                      icon="CornerUpLeftIcon"
                      size="16"
                      class="mr-1"
                  />Lista Ricerche
              </b-button>
            </b-col>
          </b-row>
            
        </b-card>

        <div v-else>
            <div class="text-center my-3" v-if="Caricato == false">
                <b-spinner  v-if="errore_caricamento == false"
                    variant="primary"
                    style="width: 3rem; height: 3rem;"
                    label="loading"
                />
                <div class="pt-1 text-primary" style="display:block;">Caricamento</div>
            </div>

            <div v-if="Caricato">
                <b-card>
                    <b-card-header class="pt-0 pl-0 pb-25">
                    <b-card-title>
                        {{ this.campiform.customer.company_name }}
                    </b-card-title>
                    <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="primary"
                        class="btn-icon rounded-circle"
                        id="`sidebar-azioni`"
                        @click="sidebarActionShow('1', this.campiform.customer.company_name)"
                    >
                        <feather-icon icon="ArrowRightIcon" />
                    </b-button>
                    </b-card-header>

                    <b-card-body class="px-0 py-0">
                        <b-row>
                            <b-col
                            cols="21"
                            xl="6"
                            >
                                <div class="pb-50 mb-0">
                                    <span class="card-text">Ref. amministrativo</span><br />
                                    <strong>{{ this.campiform.customer.nome_e_cognome }}</strong><br />
                                    <a :href="`mailto:${this.campiform.customer.email}`" class="card-text">{{ this.campiform.customer.email }}</a><br />
                                    <a :href="`mailto:${this.campiform.customer.pec}`" class="card-text">{{ this.campiform.customer.pec }}</a>

                                    <div v-if="this.campiform.customer.fisso !== null" class="py-0 my-0">
                                        <a :href="`tel:${this.campiform.customer.fisso}`" class="card-text">{{ this.campiform.customer.fisso }}</a>
                                    </div>
                                    <div v-if="this.campiform.customer.mobile !== null" class="py-0 my-0">
                                        <a :href="`tel:${this.campiform.customer.mobile}`" class="card-text">{{ this.campiform.customer.mobile }}</a>
                                    </div>
                                </div>
                            </b-col>
                            
                            <b-col
                            cols="12"
                            xl="6"
                            >
                                <p class="pb-50 mb-0">
                                    <span class="card-text">Ref. coloqui (possono essere più di uno)</span><br />
                                    NON PREVISTO AL MOMENTO DELLA CREAZIONE DELL'AZIENDA (CUSTOMER) - QUINDI DA FARE DA ZERO ... MAGARI QUI<HR />
                                    <strong>nome e cognome</strong><br />
                                    <a href="#" class="card-text">mario.rappresetanza@gmail.com</a><br />
                                    <a href="#" class="card-text">333.2589654</a> 
                                </p>


                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>

                <b-sidebar
                    ref="sidebar_azioni_licenze"
                    id="sidebar-right-azioni-licenze"
                    bg-variant="white"
                    title="Nome Agente"
                    right
                    backdrop
                    shadow
                    >
                    <div>
                        <div class="py-0 px-2">
                        
                        <div class="divider">
                            <div class="divider-text text-primary fw-bolder">DETTAGLI</div>
                        </div>
                        <b-row cols="1">
                            <b-col class="riga_sidebar">
                            <span class="font-weight-bold">Ref:</span> abc
                            </b-col>
                            <b-col class="riga_sidebar">
                            <span class="font-weight-bold">status:</span> 123
                            </b-col>
                        </b-row>

                        <div class="divider">
                            <div class="divider-text text-primary fw-bolder mt-2">AZIONI</div>
                        </div>

                        </div>

                    </div>
                </b-sidebar>

                <b-tabs pills>
                    <b-tab active>
                        <template #title>
                            <feather-icon
                            icon="InfoIcon"
                            size="18"
                            class="mr-50"
                            />
                            <span class="font-weight-bold">Dettagli</span>
                        </template>

                        <b-card>
                            <b-row>
                                <b-col md="12">
                                <showdata field_name="Obittivo" :field_value="this.campiform.research.objective" />
                                </b-col>
                                <b-col md="4">
                                <showdata field_name="Licenza" :field_value="this.campiform.licenza.ref_code" />
                                </b-col>
                                <b-col md="8">
                                <showdata field_name="Stato" :field_value="this.campiform.relazioni.stato.name" />
                                </b-col>

                                <b-col md="4">
                                <showdata field_name="Profilo primario" :field_value="this.campiform.relazioni.profilo_primario.name" />
                                </b-col>
                                <b-col md="4">
                                <showdata field_name="Profilo secondario" :field_value="this.campiform.relazioni.profilo_secondario.name" />
                                </b-col>
                                <b-col md="4">
                                <showdata field_name="Esperianza di vendita" :field_value=convertTrueFalse(this.campiform.research.sales_experience) />
                                </b-col>

                                <b-col md="4">
                                <showdata field_name="Settore princiaple" :field_value="this.campiform.relazioni.settore_primario.name" />
                                </b-col>
                                <b-col md="4">
                                    <dl>
                                        <dt class="text-muted">Settori collaterale</dt>
                                        <dd><span v-for="(element, index) in this.campiform.sectors" :key="index">{{ element.name }}, </span></dd>
                                    </dl>
                                </b-col>                        
                                <b-col md="2">
                                <showdata field_name="Esperianza di settore" :field_value=convertTrueFalse(this.campiform.research.sector_experience) />
                                </b-col>                      
                                <b-col md="2">
                                <showdata field_name="Fascia età" :field_value="this.campiform.relazioni.fascia_eta.name" />
                                </b-col>
                                
                                <b-col md="4">
                                <showdata field_name="Prodotti e Servizi" :field_value="this.campiform.research.products_services" />
                                </b-col>
                                <b-col md="4">
                                <showdata field_name="Clienti da raggiungere" :field_value="this.campiform.relazioni.target_clienti.name" />
                                </b-col>
                                <b-col md="4">
                                    <dl>
                                        <dt class="text-muted">Competitor</dt>
                                        <dd><span v-for="(element, index) in this.campiform.competitors" :key="index">{{ element.name }}, </span></dd>
                                    </dl>
                                </b-col>

                                <b-col md="12">
                                    <dl>
                                        <dt class="text-muted">Offerta</dt>
                                        <dd><span v-for="(element, index) in this.campiform.offerts" :key="index">{{ element.name }}, </span></dd>
                                    </dl>
                                </b-col>
                                <b-col md="12">
                                    <dl>
                                        <dt class="text-muted">Requisiti</dt>
                                        <dd><span v-for="(element, index) in this.campiform.requirements" :key="index">{{ element.name }}, </span></dd>
                                    </dl>
                                </b-col>

                                <b-col md="12">
                                <showdata field_name="Note" :field_value="this.campiform.research.note" />
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-tab>

                    <b-tab>
                        <template #title>
                            <feather-icon
                            icon="FileTextIcon"
                            size="18"
                            class="mr-50"
                            />
                            <span class="font-weight-bold">Annuncio</span>
                        </template>

                        <b-card>
                            <b-row>
                                <b-col cols="12">
                                    <showdata field_name="Titolo" :field_value="this.campiform.research.title_adv" />
                                </b-col>
                                <b-col cols="12">
                                    <dl>
                                        <dt class="text-muted">Corpo</dt>
                                        <dd v-html="this.campiform.research.body_adv"></dd>
                                    </dl>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-tab>

                    <b-tab>
                        <template #title>
                            <feather-icon
                            icon="MailIcon"
                            size="18"
                            class="mr-50"
                            />
                            <span class="font-weight-bold">Email Marketing</span>
                        </template>

                        <b-card>
                            <b-row>
                                <b-col cols="12">
                                    <showdata field_name="Oggetto" :field_value="this.campiform.research.object_email" />
                                </b-col>
                                <b-col cols="12">
                                    <showdata field_name="Placeholder" :field_value="this.campiform.research.placeholder_email" />
                                </b-col>
                                <b-col cols="12">
                                    <dl>
                                        <dt class="text-muted">Corpo</dt>
                                        <dd v-html="this.campiform.research.body_email"></dd>
                                    </dl>
                                </b-col>
                            </b-row>           
                        </b-card>
                    </b-tab>

                    <b-tab>
                        <template #title>
                            <feather-icon
                            icon="DatabaseIcon"
                            size="18"
                            class="mr-50"
                            />
                            <span class="font-weight-bold">Candidati</span>
                        </template>

                        <b-card>
                            tabella con vue-table con le seguenti colonne:<br/> 
                            Nome e Cognome, 
        Provincia ,
        Email ,
        Cellulare ,
        Data Can.,
        Tipo ,
        Stato,
        Screening



                        </b-card>
                    </b-tab>

                    <b-tab>
                        <template #title>
                            <feather-icon
                            icon="SlidersIcon"
                            size="18"
                            class="mr-50"
                            />
                            <span class="font-weight-bold">Database</span>
                        </template>

                        <b-card>
                            tabella con vue-table con le seguenti colonne:<br/> 
                            Nome e Cognome,
        Provincia ,
        Email ,
        Cellulare ,
        Data Ulti.,
        Allineamento,
        Funzine (candida e apre show agente per aprire la finestra con sidebar laterale)


                        </b-card>
                    </b-tab>

                    <b-tab>
                        <template #title>
                            <feather-icon
                            icon="CalendarIcon"
                            size="18"
                            class="mr-50"
                            />
                            <span class="font-weight-bold">Colloqui</span>
                        </template>

                        <b-card>
                            tabella con vue-table con le seguenti colonne:<br/> 
                            Data,
                            Ora,
                            Stato,
                            Candidato,
                            Rec,


                        </b-card>
                    </b-tab>
                </b-tabs>
            </div>

        </div>

    </div>
</template>

<script>
import router from '@/router'
import {
  BCard, BCardTitle, BCardHeader, BCardBody, BCardText, BSpinner, BButton, BBadge, BSidebar, VBToggle, BAvatar, BRow, BCol, BTabs, BTab, VBTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import showdata from '@core/components/showdata/showdata.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BCard, BCardTitle, BCardHeader, BCardBody, BCardText, BSpinner, BButton, BBadge, BSidebar,
    VBToggle, BRow, BCol, BAvatar, BTabs, BTab, VBTooltip,

    showdata,
    AppTimeline,
    AppTimelineItem,
  },
  
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
        campiform: [ ],
        infoSideBar: {
            id: '',
            titolo: '',
        },
        Caricato: false,
        errore_caricamento: false,
    }
  }, 
  created() { 
    
    const schedaAgenteBase = this.$http.get('api/crm/research/schedabase/'+router.currentRoute.params.id_riga)

    Promise.all([schedaAgenteBase]).then(response => {
        if(response[0].data.statusCode===200){
            //caricamento dati scheda Agente
            this.campiform = response[0].data.reply

            console.log(this.campiform)

            this.Caricato = true;
            this.errore_caricamento = false;

        } else {
            this.Caricato = false;
            this.errore_caricamento = true;
        }
    });
  },
  methods: {
    sidebarActionShow(id, titolo) {
        console.log("id -> "+id+"\ntitolo -> "+titolo);
        this.infoSideBar = { id: id, titolo: titolo };

        this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
    },
    convertTrueFalse(valore){
        let risposta
        if(valore === true){
            risposta = 'Si'
        } else {
            risposta = 'No'
        }
        return risposta
    }
  }
}
</script>